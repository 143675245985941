import { arrayOf, bool, node, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import CarouselControls from "~components/CarouselControls";
import HorizontalScrollbar from "~components/HorizontalScrollbar";
import classnames from "~utils/classnames";

import classes from "./ItemRow.module.scss";

const ItemRow = ({
  children,
  inline,
  showArrows = true,
  className,
  barClassname,
  buttonsClassname,
}) => {
  const [overflow, setOverflow] = useState(false);
  const containerRef = useRef(null);
  const [scrollState, setScrollState] = useState({
    prevEnabled: false,
    nextEnabled: true,
  });

  const rowClassname = classnames(
    overflow ? classes.row_overflow : classes.row,
    inline && classes.row_inline,
  );

  const updateScrollState = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const scrollWidth = container.scrollWidth;
    const scrollBarEnabled = scrollWidth > containerWidth;

    setOverflow(scrollBarEnabled);
    setScrollState({
      prevEnabled: container.scrollLeft > 0,
      nextEnabled: container.scrollLeft + containerWidth <= scrollWidth - 1,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    container.addEventListener("scroll", updateScrollState);
    return () => container.removeEventListener("scroll", updateScrollState);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver(updateScrollState);
    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const newScrollLeft = Math.max(
      0,
      (container.scrollLeft += direction * containerWidth),
    );
    container.scrollLeft = newScrollLeft;

    const scrollWidth = container.scrollWidth;

    setScrollState({
      prevEnabled: newScrollLeft > 0,
      nextEnabled: newScrollLeft + containerWidth <= scrollWidth - 1,
    });
  };

  return (
    <div className={classnames(classes.ItemRow, className)}>
      <div
        style={{
          "--left-mask-size": scrollState.prevEnabled ? "7.5rem" : "0",
          "--right-mask-size": scrollState.nextEnabled ? "7.5rem" : "0",
        }}
        className={classes.scrollContainer}
      >
        <div ref={containerRef} className={rowClassname}>
          {children}
        </div>
      </div>
      {overflow && (
        <>
          <HorizontalScrollbar
            containerRef={containerRef}
            barClassname={barClassname}
          />

          {showArrows && (
            <CarouselControls
              prevEnabled={scrollState.prevEnabled}
              nextEnabled={scrollState.nextEnabled}
              prevOnClick={() => handleScroll(-1)}
              nextOnClick={() => handleScroll(1)}
              prevIconClassname={classnames(
                buttonsClassname,
                classes.arrow_position,
              )}
              nextIconClassname={classnames(
                buttonsClassname,
                classes.arrow_position,
              )}
            />
          )}
        </>
      )}
    </div>
  );
};

ItemRow.propTypes = {
  children: arrayOf(node).isRequired,
  showArrows: bool,
  inline: bool,
  className: string,
  barClassname: string,
  buttonsClassname: string,
};

export default ItemRow;
