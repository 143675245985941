import { useTranslation } from "next-i18next";
import { any, bool, func, oneOfType, shape, string } from "prop-types";

import IconButton from "~components/IconButton";
import classnames from "~utils/classnames";

import classes from "./CarouselControls.module.scss";

const CarouselControls = ({
  prevIconClassname,
  nextIconClassname,
  prevRef,
  nextRef,
  prevOnClick,
  nextOnClick,
  prevEnabled,
  nextEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {prevEnabled && (
        <IconButton
          ref={prevRef}
          color="tertiary"
          disabled={!prevEnabled}
          className={classnames(prevIconClassname, classes.arrow_left)}
          icon="arrowLeftThin"
          onClick={prevOnClick}
          title={t("previous")}
        />
      )}

      {nextEnabled && (
        <IconButton
          ref={nextRef}
          color="tertiary"
          disabled={!nextEnabled}
          className={classnames(nextIconClassname, classes.arrow_right)}
          icon="arrowRightThin"
          onClick={nextOnClick}
          title={t("next")}
        />
      )}
    </>
  );
};

CarouselControls.propTypes = {
  prevIconClassname: string,
  nextIconClassname: string,
  prevRef: oneOfType([func, shape({ current: any })]),
  nextRef: oneOfType([func, shape({ current: any })]),
  prevOnClick: func,
  nextOnClick: func,
  prevEnabled: bool,
  nextEnabled: bool,
};

export default CarouselControls;
